import React from "react";
import { PopupButton } from "react-calendly";
import "./styles.css";

const index = () => {
  return (
    <>
      <PopupButton 
      className='button' 
      styles={{}} 
      text='Schedule with Calendly' 
      url='https://calendly.com/kaura-app/phungo' />
    </>
  );
};

export default index;
