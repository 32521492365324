import React from "react"
import { useState } from "react"
import { Row, Col, Drawer } from "antd"

import Container from "../../common/Container"
import { SvgIcon } from "../../common/SvgIcon"
import { Button } from "../../common/Button"
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Span,
} from "./styles"

import { Cross as Hamburger } from "hamburger-react"

const Header = () => {
  const [visible, setVisibility] = useState(false)

  const onClose = () => {
    setVisibility(!visible)
  }

  //----------------//
  const MenuItem = () => {
    const scrollTo = id => {
      const element = document.getElementById(id)
      element.scrollIntoView({
        behavior: "smooth",
      })
      setVisibility(false)
    }

    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("pricing")}>
          <Span>Pricing</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("livedemo")}>
          <Span>Live Demo</Span>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => window.open("https://use.kaura.app", "_self")}
        >
          <Span>
            <Button>{"Get Started"}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    )
  }
  //----------------//

  // const scrollTop = () => {
  //   const element = document.getElementById("intro");
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "nearest",
  //   });
  // };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer>
            <SvgIcon
              src="logo.png"
              aria-label="homepage"
              width="144px"
              height="40px"
            />
          </LogoContainer>

          <NotHidden>
            <MenuItem />
          </NotHidden>

          <Burger style={{ zIndex: 2000 }}>
            <Hamburger
              toggled={visible}
              toggle={setVisibility}
              direction="right"
              duration={0.4}
              color="#A87DFF"
              label="Hamburger menu"
              hideOutline={false}
            />
          </Burger>
        </Row>

        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label>
              <Col span={12}>
                <Menu></Menu>
              </Col>
            </Label>
          </Col>

          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  )
}

export default Header
