import styled from "styled-components";

export const StyledButton = styled.button`
  background: ${(p) => p.color || "#A87DFF"};
  color: ${(p) => (p.color ? "#A87DFF" : "#fff")};
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  border: 1px solid #A87DFF;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover {
    color: #fff;
    border: 1px solid #FE7624;
    background-color: #FE7624;
  }
`;
