import React from "react"
import { Row, Col } from "antd"
import { SvgIcon } from "../../common/SvgIcon"
import { Fade } from "react-awesome-reveal"
import TextTransition, { presets } from "react-text-transition"

import {
  RightContentSection,
  Content,
  ContentWrapper,
  InnerWrapper,
} from "./styles"

const TEXTS = [
  "Have you missed deadlines?",
  "Feeling like estimating is a waste of time?",
  "Cannot set concrete expectations with your customers and stakeholders?",
]

const MissedDeadlinesBlock = ({ id, icon, content }) => {
  //speed for text animation.
  const [index, setIndex] = React.useState(0)
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(index => index + 1),
      3000 // every x seconds
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <RightContentSection>
      <Fade>
        <Row justify="space-between" align="middle" id={id}>
          <Col lg={11} md={11} sm={11} xs={24}>
            <ContentWrapper>
              <InnerWrapper>
                <h2>
                  <TextTransition
                    direction="up"
                    noOverflow={true}
                    text={TEXTS[index % TEXTS.length]}
                    springConfig={presets.slow}
                  />
                </h2>
              </InnerWrapper>

              <Content>{content}</Content>
            </ContentWrapper>
          </Col>

          <Col lg={11} md={11} sm={12} xs={24}>
            <SvgIcon src={icon} width="80%" height="100%" />
          </Col>
        </Row>
      </Fade>
    </RightContentSection>
  )
}

export default MissedDeadlinesBlock
