import React from "react"

import { GlobalStyles } from "./globalstyles"

import Header from "./Header"
import Footer from "./Footer"
import Esseo from "./Esseo"

export default function Layout({ children }) {
  return (
    <div>
      <GlobalStyles />
      <Esseo/>
      <Header />
      {children}
      <Footer />
    </div>
  )
}
