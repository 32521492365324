import React from 'react';
import { Fade } from "react-awesome-reveal";
import paperchart from "../../images/paperchart.jpg";

const BigImageBlock = () => {
  return (
    <>
      <Fade>
        <img
          id='bigimage'
          src={paperchart}
          alt='paperchart'
          style={{ display: "block", marginTop: "40px", marginLeft: "auto", marginRight: "auto", width: "100%" }}
        />
      </Fade>
    </>
  );
};

export default BigImageBlock;
