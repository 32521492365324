import React from 'react';
import { Row, Col } from "antd";
import { Fade } from "react-awesome-reveal";
import { Button } from "../../common/Button";
import { IntroBlockSection, Content, ContentWrapper } from "./styles";


const IntroBlock = ({ title, content, button, id }) => {
  // const scrollTo = (id: string) => {
  //   const element = document.getElementById(id) as HTMLDivElement;
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };
  return (
    <IntroBlockSection id={id}>
      <Fade >
        <Row justify='center' align='middle'>
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h1>{title}</h1>
              <Content>{content}</Content>
              {button && (
                <Button name='submit' onClick={() => window.open("https://use.kaura.app", "_self")}
                >
                  {button}
                </Button>
              )}
            </Col>
          </ContentWrapper>
        </Row>
      </Fade>
    </IntroBlockSection>
  );
};

export default IntroBlock;
