import styled from "styled-components";

export const FooterSection = styled("footer")`
  background: #2B244D;
  padding: 2.5rem 0;
`;

export const Extra = styled("section")`
  background: #2B244D;
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
  // cursor: pointer;
`;

export const FooterContainer = styled("div")`
  max-width: 510px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 769px) {
    width: auto;
    float: right;

    a:not(:last-child) {
      display: none;
    }
  }

  div {
    cursor: pointer;
    margin-right: 15px;
    width: 25px;
    height: 25px;

    &:hover {
      fill: #fe7624;
    }
  }
`;

export const Para = styled("div")`
  color: #ffffff;
  font-size: 12px;
  text-align: left;
`;

export const PrivacyDiv = styled("div")`
  color: #ffffff;
  font-size: 12px;
  text-align: left;

  @media only screen and (max-width: 769px) {
    text-align: right;
  }
`;


