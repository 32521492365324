import styled from "styled-components"

export const RightContentSection = styled("section")`
  position: relative;
  padding: 8rem 0 8rem;

  @media only screen and (max-width: 1024px) {
    padding: 6rem 0 6rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 1rem 0 1rem;
  }
`

export const ContentWrapper = styled("div")`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 575px) {
    padding-bottom: 1rem;
  }
`

export const InnerWrapper = styled("div")`
  position: relative;
  height: 320px;
  display: flex;
  align-items:flex-end;

  @media only screen and (max-width: 414px) {
    height: 200px;
  }
`

export const Content = styled("p")`
  margin: 0;
  
`
