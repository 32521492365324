import styled from "styled-components";

export const ScrollUpContainer = styled("div")`
  padding: 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  cursor: pointer;
  background: rgb(241, 242, 243);
  text-align: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  visibility: ${(p) => (p.show ? "visible" : "hidden")};
  opacity: ${(p) => (p.show ? "1" : "0")};
  display: flex;

  &:hover {
    background: #FE7624;
  }

  @media screen and (max-width: 769px) {
    display: none;
  }
`;
