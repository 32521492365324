import React from "react"
import { Row, Col } from "antd"
import Container from "../../common/Container"

import {
  FooterSection,
  Extra,
  LogoContainer,
  FooterContainer,
  Para,
  PrivacyDiv,
} from "./styles"

const Footer = () => {
  // const scrollUp = () => {
  //   const element = document.getElementById("intro")
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "nearest",
  //   })
  // }

  return (
    <>
      <Extra>
        <Container border={false}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <Col lg={12} md={12} sm={12} xs={12}>
              <LogoContainer>
                <img
                  src="/img/svg/logo.png"
                  alt="logo"
                  aria-label="homepage"
                  width="144px"
                  height="40px"
                />
              </LogoContainer>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <FooterContainer>
               
                  <a
                    href="mailto:ptn123@gmail.com?cc=dpjnguyen@gmail.com&subject=Questions for Kaura&body=Hi Kaura Team, %0d%0dJust want to say I love Kaura%20:)%20 %0d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/color/30/000000/email.png"
                      alt="email"
                    />
                  </a>
              
              </FooterContainer>
            </Col>
          </Row>
        </Container>
      </Extra>

      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Para>Copyright © 2021 Kaura. All Rights Reserved.</Para>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <PrivacyDiv>Privacy Policy</PrivacyDiv>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  )
}

export default Footer
