import React from "react";
import { Card } from "antd";
import { Button } from "../../../common/Button";

import free from "../../../images/free.png";

const PricingCard = () => {
  const cardStyle = {
    marginTop: "1rem",
    paddingTop: "1.5rem",
    maxWidth: "600px",
    minHeight: "420px",
    boxShadow: "5px 8px 24px 5px rgb(255, 125, 239, .5)",
  };

  return (
    <>
      <Card
        title={
          <>
            <h4 style={{ fontSize: "1.7rem" }}>Monthly</h4>
            <p style={{ fontSize: "1.3rem" }}>
              <span style={{ fontWeight: "bold" }}>$0</span> <span> / Month </span>
            </p>
          </>
        }
        extra={
          <>
            <img src={free} alt='free' style={{ position: "absolute", top: 0, right: 0, width: "95px" }} />
          </>
        }
        headStyle={{ backgroundColor: "white" }}
        bordered
        style={cardStyle}
        bodyStyle={{ backgroundColor: "white" }}
      >
        <div style={{ marginLeft: "0rem", marginBottom: "2rem" }}>
          <div style={{ textAlign: "left", fontSize: "1rem" }}>+ Cycle Time Scatter Plot</div>
          <div style={{ textAlign: "left", fontSize: "1rem" }}>+ Monte Carlo Simulation - When</div>
          <div style={{ textAlign: "left", fontSize: "1rem" }}>+ Monte Carlo Simulation - How Many</div>
          <div style={{ textAlign: "left", fontSize: "1rem" }}>+ Use your own data or sample data</div>
          <div style={{ textAlign: "left", fontSize: "1rem" }}>+ Your data fully private</div>
        </div>
        <Button name='submit' onClick={() => window.open("https://use.kaura.app", "_self")}>
          Get Started
        </Button>
      </Card>
    </>
  );
};

export default PricingCard;
