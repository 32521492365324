import React from "react"
import { Row, Col } from "antd"
import { SvgIcon } from "../../common/SvgIcon"
import { Button } from "../../common/Button"
import { Fade } from "react-awesome-reveal"

import {
  LeftContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  ButtonWrapper,
  MinTitle,
  MinPara,
  ImageWrapper,
} from "./styles"

const LeftContentBlock = ({ id, icon, title, content, section, button }) => {
  // const scrollTo = (id: string) => {
  //   const element = document.getElementById(id) as HTMLDivElement;
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };
  return (
    <LeftContentSection>
      <Fade>
        <Row justify="space-between" align="middle" id={id}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <ImageWrapper>
              <SvgIcon src={icon} width="100%" height="100%" />
            </ImageWrapper>
          </Col>
          <Col lg={11} md={11} sm={11} xs={24}>
            <ContentWrapper>
              <h6>{title}</h6>
              <Content>{content}</Content>
              <ServiceWrapper>
                <Row justify="space-between">
                  {typeof section === "object" &&
                    section.map((item, id) => {
                      return (
                        <Col key={id} span={11}>
                          <SvgIcon src={item.icon} width="60px" height="60px" />
                          <MinTitle>{item.title}</MinTitle>
                          <MinPara>{item.content}</MinPara>
                        </Col>
                      )
                    })}
                </Row>
              </ServiceWrapper>
              <ButtonWrapper>
                {typeof button === "object" &&
                  button.map((item, id) => {
                    return (
                      <Button
                        key={id}
                        color={item.color}
                        fixedWidth={true}
                        onClick={() =>
                          window.open("https://use.kaura.app", "_self")
                        }
                      >
                        {item.title}
                      </Button>
                    )
                  })}
              </ButtonWrapper>
            </ContentWrapper>
          </Col>
        </Row>
      </Fade>
    </LeftContentSection>
  )
}

export default LeftContentBlock
