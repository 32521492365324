import React from "react"

import Layout from "../components/Layout"

import Container from "../common/Container"
import ScrollToTop from "../common/ScrollToTop"

import IntroBlock from "../components/IntroBlock"
import BigImageBlock from "../components/BigImageBlock/BigImageBlock"
import MissedDeadlinesBlock from "../components/MissedDeadlinesBlock"
import LeftContentBlock from "../components/LeftContentBlock"
import RightContentBlock from "../components/RightContentBlock"
import PricingBlock from "../components/PricingBlock"
import LiveDemoBlock from "../components/LiveDemoBlock"

export default function Home() {
  return (
    <>
      <Layout>
        <Container>
          <ScrollToTop />
          <IntroBlock
            id="intro"
            title="Forecast Using Data You Already Have"
            content="Within a few minutes, you can use your Jira data to accurately forecast when your team can complete their backlog. #NoEstimates"
            button="Get Started"
          />

          <BigImageBlock />

          <MissedDeadlinesBlock
            id="deadlines"
            content="Forecast with confidence based on the Jira data that you already have with our advance yet user friendly Monte Carlo Simulation and Cycle Time Scatter Plot tools.
        "
            icon="calendar.png"
          />

          <LeftContentBlock
            id="ctsp"
            title="Cycle Time Scatter Plot"
            content="See patterns of when and how long it takes your team to complete each item by plotting your completed Jira items over time."
            icon="graphiso.svg"
          />
          
          <RightContentBlock
            id="montecarlo"
            title="Monte Carlo Simulation"
            content="Forecast when your team can finish the backlog or how many items your team will complete by a certain date by simulating your team's past performance into the future."
            icon="normal.svg"
          />

          <LeftContentBlock
            id="dataprivacy"
            title="Your Data Privacy"
            content="We do not store any of your data, in fact we don't even have a database. Kaura is a lightweight app that simply passes your Jira API key to the Jira's REST API. The returned data from Jira is then used in our calculations engine and displayed in the browser via serverless functions and the javascripts in your web browser."
            icon="dataprivacy.svg"
          />

          <PricingBlock
            id="pricing"
            title="Pricing"
            content="Kaura Standalone SaaS"
          />

          <LiveDemoBlock
            id="livedemo"
            title="Live Demo"
            content="To learn more about Kaura and get a Live Demo from us, simply book a spot on our calendar using Calendly."
            icon="lightbulb.png"
          />
        </Container>
      </Layout>
    </>
  )
}
